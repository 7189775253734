@font-face {
  font-family: "CoreSansE";
  src: url(fonts/sans/CoreSansE-15Thin.otf) format("opentype");
  font-weight: 15;
  font-style: normal;
}

@font-face {
  font-family: "CoreSansE";
  src: url(fonts/sans/CoreSansE-15ThinIt.otf) format("opentype");
  font-weight: 15;
  font-style: italic;
}

@font-face {
  font-family: "CoreSansE";
  src: url(fonts/sans/CoreSansE-25ExtraLight.otf) format("opentype");
  font-weight: 25;
  font-style: normal;
}
@font-face {
  font-family: "CoreSansE";
  src: url(fonts/sans/CoreSansE-25ExtraLightIt.otf) format("opentype");
  font-weight: 25;
  font-style: italic;
}

@font-face {
  font-family: "CoreSansE";
  src: url(fonts/sans/CoreSansE-35Light.otf) format("opentype");
  font-weight: 35;
  font-style: normal;
}
@font-face {
  font-family: "CoreSansE";
  src: url(fonts/sans/CoreSansE-35LightIt.otf) format("opentype");
  font-weight: 35;
  font-style: italic;
}
@font-face {
  font-family: "CoreSansE";
  src: url(fonts/sans/CoreSansE-45Regular.otf) format("opentype");
  font-weight: 45;
  font-style: normal;
}
@font-face {
  font-family: "CoreSansE";
  src: url(fonts/sans/CoreSansE-45RegularIt.otf) format("opentype");
  font-weight: 45;
  font-style: italic;
}
@font-face {
  font-family: "CoreSansE";
  src: url(fonts/sans/CoreSansE-55Medium.otf) format("opentype");
  font-weight: 55;
  font-style: normal;
}
@font-face {
  font-family: "CoreSansE";
  src: url(fonts/sans/CoreSansE-55MediumIt.otf) format("opentype");
  font-weight: 55;
  font-style: italic;
}
@font-face {
  font-family: "CoreSansE";
  src: url(fonts/sans/CoreSansE-65Bold.otf) format("opentype");
  font-weight: 65;
  font-style: normal;
}
@font-face {
  font-family: "CoreSansE";
  src: url(fonts/sans/CoreSansE-65BoldIt.otf) format("opentype");
  font-weight: 65;
  font-style: italic;
}
@font-face {
  font-family: "CoreSansE";
  src: url(fonts/sans/CoreSansE-75ExtraBold.otf) format("opentype");
  font-weight: 75;
  font-style: normal;
}
@font-face {
  font-family: "CoreSansE";
  src: url(fonts/sans/CoreSansE-75ExtraBoldIt.otf) format("opentype");
  font-weight: 75;
  font-style: italic;
}
@font-face {
  font-family: "CoreSansE";
  src: url(fonts/sans/CoreSansE-85Heavy.otf) format("opentype");
  font-weight: 85;
  font-style: normal;
}
@font-face {
  font-family: "CoreSansE";
  src: url(fonts/sans/CoreSansE-85HeavyIt.otf) format("opentype");
  font-weight: 85;
  font-style: italic;
}
@font-face {
  font-family: "CoreSansE";
  src: url(fonts/sans/CoreSansE-95Black.otf) format("opentype");
  font-weight: 95;
  font-style: normal;
}

.App {
  font-family: "CoreSansE", Arial;
  text-align: center;
  background-color: #f5f5f5;
  max-width: 100wv;
  overflow-x: hidden;
  /* min-height: 100vh; */
}

/*  
    bg
    24272C
    323941

    text
    FFFFFF
    DEDEDE
    A0A1A3

    button-green
    447B20

*/
